// @import './base/fonts';

// //Systems
// @import './systems/colors';
// @import './systems/font-size';
// @import './systems/font-weight';
// @import './systems/radius';
// @import './systems/border-radius';
// @import './systems/font-colors';
// @import './systems/background-colors';
// @import './systems/actions';
// @import './systems/margin-spacing';
// @import './systems/padding-spacing';
// @import './systems/w-h-classes';
// @import './systems/progress';
// @import './systems/buttons';
// @import './systems/borders';

// //Antd Classes
// @import './antd-forced-classes';
// @import './auth-layout';

// //Pages
// @import './pages/dashboard';
// @import './pages/activity-log';


// html {
//     background: #EFEFEF;
// }


.bg-gradient {
    background: rgb(123,78,170);
    background: linear-gradient(180deg, rgba(123,78,170,1) 0%, rgba(147,90,192,1) 35%, rgba(206,118,248,1) 100%);
}

$color-primary: #935ac0;

body {
    background: #EFEFEF !important;
    padding: 0px;
    margin: 0px;
}

.app-layout {
    &.ant-layout {
        background: #EFEFEF !important;

        .ant-layout-sider {
            position: sticky;
            top: 18px;
            margin: 16px;
            margin-right: 0px;
            background: rgb(123,78,170);
            background: linear-gradient(180deg, rgb(123, 78, 170) 0%, rgba(147,90,192,1) 60%, rgba(206,118,248,1) 100%);
                        border-radius: 30px;
            width: 70px !important;
            max-width: 70px !important;
            min-width: 70px !important;
            height: calc(100vh - 32px);
        }
    }
}

.ant-layout {
    background: #EFEFEF !important;
}

.d-block {
    display: block;
}

.App{
    height: 100vh;
}

.menu {
    margin-top: 150px;
    width: 70px !important;
    max-width: 70px !important;
    height: calc(100vh - 330px);
}

.menu-item {
    width: 70px !important;
    display: flex;
    margin-bottom: 50px;
    justify-content: center;

    svg {
        width: 40px;
        height: 40px;
    }
}

.main-content {
    padding: 16px;
}

.p-16 {
    padding: 16px;
}

.m-0 {
    margin: 0px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}


.m-24 {
    margin: 24px;
}


.m-16 {
    margin: 16px;
}

.mr-8 {
    margin-right: 8px !important;
}

.w-full {
    width: 100%;
}

.mt-16 {
    margin-top: 16px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.pt-16 {
    padding-top: 16px !important;
}

.pb-16 {
    padding-bottom: 16px !important;
}

.ant-card {
    border-color: #EFEFEF !important;
}

.ant-card-head {
    border-bottom-width: 0px !important;
}

.ant-card-body {
    border-color: #EFEFEF !important;
    // padding-top: 0px !important;
}

.ant-card.pt-0 .ant-card-body {
    padding-top: 0px !important;
}

.shortcut-item {
    border-radius: 8px;
    padding: 16px;
    height: 100%;

    img {
        margin-bottom: 8px;
    }
}

.bg-cloud {
    background: #F5F6F7;
}

.text-red {
    color: #f44336;
}

.mt-24 {
    margin-top: 24px;
}

.video-card {
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    width: 100%;
    min-height: 100px;
    color: #fff;
    background: $color-primary !important;
}

.ant-collapse-content-box {
    padding: 0px !important;
}

// .ant-pagination li:not(.ant-pagination-disabled) button{
//     color: #fff;
// }


.current-video{
    background-color: #dbdbdb !important;
}

.ant-form-item-explain-error {
    white-space: pre-wrap;
}